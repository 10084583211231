function stickyElementInit() {
	const stickyElementToggler = document.querySelector(
		'[data-sticky] [type="button"]',
	);
	const variable = '--sticky-position';
	const stickyElement = document.querySelector('[data-sticky]');
	const stickyClass = 'element-is-sticky';

	if (stickyElement) {
		setPositionToVar(stickyElementToggler, variable);
		window.onscroll = function() {
			var stickyPositionY = getComputedStyle(
				document.documentElement,
			).getPropertyValue(variable);
			if (window.pageYOffset > stickyPositionY) {
				stickyElement.classList.add(stickyClass);
			} else {
				stickyElement.classList.remove(stickyClass);
			}
		};
	}
}

function setPositionToVar(el, variable) {
	const position = offset(el).top + el.offsetHeight;
	function offset(el) {
		var rect = el.getBoundingClientRect();
		var scrollTop = window.pageYOffset;
		return { top: rect.top + scrollTop };
	}
	document.documentElement.style.setProperty(variable, position);
}
