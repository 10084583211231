// events on load
document.addEventListener('DOMContentLoaded', () => {
	// svg4everybody();
	observerInit();
	lazyloadInit();
	modalInit();
	calculateProgress();

	// transfered
	mobileMenuInit(
		'[data-nav-toggler]',
		'[data-nav-target]',
		'nav-is-open',
		'[data-nav-overlay]',
	);

	// transfered
	mobileMenuInit(
		'[data-search-toggler]',
		'[data-search-target]',
		'search-is-open',
	);

	// transfered
	mobileVhInit();
	getScrollbarWidth();
	detailsInit();
	paginationInit();
	tinySliderWeeksInit();
	tinySliderProductsInit();
	productSliderInit();
	tableOfContentsInit();
});

// events on resize
window.onresize = function() {
	// transfered
	mobileVhInit();

	stickyElementInit();
};
