function mobileMenuInit(toggler, target, activeClass, overlay) {
	const wrapper = document.querySelector(".page-wrapper");
	const thisToggler = document.querySelector(toggler);
	const thisTarget = document.querySelector(target);
	const thisOverlay = document.querySelector(overlay);

	if (thisToggler) {
		thisToggler.addEventListener("click", function() {
			const menuIsOpen = this.getAttribute("aria-expanded") == "true";
			menuIsOpen
				? closeMenu(thisToggler, thisTarget)
				: openMenu(thisToggler, thisTarget);
		});
	}

	if (thisOverlay) {
		thisOverlay.addEventListener("click", function() {
			closeMenu(thisToggler, thisTarget);
		});
	}

	document.addEventListener("keydown", (event) => {
		if (event.key === "Escape" && wrapper.classList.contains(activeClass)) {
			closeMenu(thisToggler, thisTarget);
		}
	});

	function closeMenu(thisToggler, thisTarget) {
		wrapper.classList.remove(activeClass);
		thisToggler.setAttribute("aria-expanded", "false");
		thisTarget.setAttribute("aria-hidden", "true");
	}

	function openMenu(thisToggler, thisTarget) {
		wrapper.classList.add(activeClass);
		thisToggler.setAttribute("aria-expanded", "true");
		thisTarget.setAttribute("aria-hidden", "false");
	}

	return;
}
