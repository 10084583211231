function tinySliderWeeksInit() {
	const tinySlider = document.querySelector(
		'.tiny-slider--weeks .slider-slides',
	);
	if (tinySlider) {
		document.addEventListener('glider-loaded', tinySLiderLoaded);
		document.addEventListener('glider-refresh', tinySLiderLoaded);
		new Glider(tinySlider, {
			slidesToShow: 'auto',
			slidesToScroll: 1,
			itemWidth: 112,
			exactWidth: 112,
			draggable: true,
			duration: 2,
			arrows: {
				prev: '.tiny-slider--weeks .slider-control--prev',
				next: '.tiny-slider--weeks .slider-control--next',
			},
		});
	}
	function tinySLiderLoaded() {
		tinySlider.classList.add('loaded');
	}
}
