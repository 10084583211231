function productSliderInit() {
	const productSlider = document.querySelector(
		".product-slider .slider-slides"
	);
	if (productSlider) {
		document.addEventListener("glider-loaded", productSLiderLoaded);
		document.addEventListener("glider-refresh", productSLiderLoaded);

		new Glider(productSlider, {
			slidesToShow: "auto",
			slidesToScroll: 1,
			itemWidth: 238,
			exactWidth: 238,
			draggable: true,
			duration: 2,
			rewind: true,
			arrows: {
				prev: ".product-slider .slider-control--prev",
				next: ".product-slider .slider-control--next",
			},
		});

		function productSLiderLoaded() {
			productSlider.classList.add("loaded");
		}
	}
}
