function calculateProgress() {
	const progress = document.getElementById('progress');
	const progressImg = document.getElementById('progress-img');
	if (progress) {
		progressImg.style.setProperty(
			'--position',
			`${(progress.value / progress.max).toFixed(3) * 100}%`,
		);
	}
}
