function spyInit() {
	let spyControls = document.querySelectorAll(
		".contents-table--sticky ul li a"
	);
	if (spyControls.length > 0) {
		const spy = new Gumshoe(".contents-table--sticky ul li a", {
			offset: 80,
		});
		const toggler = document.querySelector(
			".contents-table--sticky [data-read-more='toggler']"
		);
		const target = document.querySelector(
			".contents-table--sticky [data-read-more='container']"
		);

		spyControls.forEach(function(item) {
			item.addEventListener("click", function() {
				if (toggler.getAttribute("aria-expanded") == "true") {
					toggler.setAttribute("aria-expanded", "false");
					target.dataset.readMoreExpanded = false;
				}
			});
		});
	}
}
