function detailsInit() {
	document.querySelectorAll(".details").forEach((details) => {
		details.addEventListener("toggle", (event) => {
			if (details.open) {
				details.classList.add("is-open");
			} else {
				details.classList.remove("is-open");
			}
		});
	});
}
