function observerInit() {
	var IntersectionObserverCallback = function(entries) {
		Array.prototype.forEach.call(entries, function(entry, i) {
			if (entry.isIntersecting) {
				entry.target.dataset.visible = "true";
			}
		});
	};

	var IntersectionObserverOptions = {
		root: null,
		rootMargin: "0px",
		threshold: 0.8
	};

	var observer = new IntersectionObserver(
		IntersectionObserverCallback,
		IntersectionObserverOptions
	);

	window.addEventListener("load", function(e) {
		var targets = document.querySelectorAll("[data-observable]");
		Array.prototype.forEach.call(targets, function(target) {
			target.dataset.visible = "false";
			observer.observe(target);
		});
	});
}