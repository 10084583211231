function tableOfContentsInit() {
	tableOfContents('[data-content]', '[data-toc]', {
		levels: 'h2, h3',
		listType: 'ul',
	});

	readMoreInit();
	spyInit();
	stickyElementInit();
}
