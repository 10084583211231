function readMoreInit() {
	const toggler = document.querySelector("[data-read-more='toggler']");
	const target = document.querySelector("[data-read-more='container']");
	const stickyElement = document.querySelector('[data-sticky]');
	const stickyClass = 'element-is-sticky';

	if (target) {
		target.style.setProperty(
			'--itemAllQty',
			target.getElementsByTagName('li').length,
		);
		target.style.setProperty('--itemMinQty', target.dataset.readMoreMinItems);
	}

	if (toggler) {
		toggler.addEventListener('click', function() {
			if (this.getAttribute('aria-expanded') == 'false') {
				this.setAttribute('aria-expanded', 'true');
				target.dataset.readMoreExpanded = true;
			} else {
				this.setAttribute('aria-expanded', 'false');
				target.dataset.readMoreExpanded = false;
			}
			if (!stickyElement.classList.contains(stickyClass)) {
				stickyElementInit();
			}
		});
	}
	return;
}
